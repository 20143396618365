.logo {
  width: 10rem;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #F9F9FA;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  width: 16rem;
  min-height: 100vh;
  border: 1px solid #f2f2f2;
}

.right {
  flex-grow: 1;
  margin: 0.5rem;
  margin-left: 3.5rem;
  display: flex;
  flex-direction: column;
}

.leftMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
}

.versionContainer {
  flex: 1;
  align-items: end;
  display: inline-flex;
}

.version {
  color: #bbb;
  font-size: 9px;
  bottom: 3px;
}
