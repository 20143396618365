.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.link {
    text-decoration: none;
}

.link:not(:first-of-type)::before {
    content: ' > ';
    color: white;
}
