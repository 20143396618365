.collection-request-entry {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 3px;
    font-size: 12px;
    opacity: 0.8;
    border-left-style: solid;
    border-left-width: 3px;
}

.collection-request-entry-header {
    display: flex;
    font-weight: bold;
    gap: 5px;
    overflow: hidden;
    justify-content: space-between;
}

.collection-request-entry-content {
    display: flex;
    gap: 5px;
    overflow: hidden;
}

.collection-request-entry-content-container-name {
    flex: 1 1 auto;
}

.collection-request-entry-content-containers-number {
    flex-shrink: 0;
    text-align: end;
}

.per-request {
    color: #0AAD50;
    background-color: rgba(10, 173, 80, 0.2);
}

.scheduled {
    color: #D9A402;
    background-color: rgba(217, 164, 2, 0.2);
}

.additional {
    color: #3c3c3c;
    background-color: rgba(223, 223, 223, 0.2);
}

.commune {
    color: #183362;
    background-color: rgba(24, 51, 98, 0.2);
}
