.innerContainer {
    display: flex;
}

.headerContainer {
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
    padding: 0.3rem;
}

.flatContainer {
    border-radius: 0.3rem;
    border: solid 1px #e1e1e1;
    background: white;
}

.headerItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
