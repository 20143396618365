.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.languageButtonsContainer {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.3rem;
}
