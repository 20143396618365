.container {
    display: flex;
}

.value-wrapper {
    padding: 0.3rem 0.6rem;
    margin-bottom: -0.3rem;
    border-radius: 0.8rem;
}

.value {
    line-height: 1rem;
    height: 1rem;
}
