.paperContainer {
    border-radius: 0.3rem;
    padding-bottom: 0.2rem;
    margin-top: 0.5rem;
}

.progressContainer {
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.dataTableContainerWrapper {
    display: grid;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.dataTableContainer {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-scrolling: auto;
}

.dataTableContainerWithMaxHeight {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
    overflow-scrolling: auto;
    max-height: 85vh;
}

.tableToolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.8rem 1rem;
}

.noDataComponent {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.rowOptionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.dataCell {
    white-space: normal;
}
