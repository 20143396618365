.container {
    border-radius: 0.3rem;
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    gap: 0.3rem;
}

.containerInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
}

.dialog {
    min-width: 30rem;
}
