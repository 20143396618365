.container {
    display: flex;
    gap: 0.3rem;
}

.label {
}

.value {
    line-height: 1rem;
    height: 1rem;
}
