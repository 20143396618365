.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.formRowContainer {
  display: flex;
  gap: 1rem;
}

.formButtonsGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3px;
  margin-top: 2rem;
  height: 4rem;
}

.formButtonsGridContainer button {
  padding: 0.6rem 2.3rem;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.formButtonsCenterItems {
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;
  align-items: center;
  grid-column: 2;
}

.formButtonsRightItems {
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: flex-end;
  align-items: center;
  grid-column: 3;
}

.header {
  font-size: 1.1rem;
  line-height: 2rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

.header-icon {
  font-size: 1.1rem;
  line-height: 2rem;
  margin: auto;
  background-color: #f2f2f2;
  width: 100%;
  padding: 7rem;
  text-align: center;
}

.header-icon svg {
  width: 3rem;
  height: 3rem;
}
