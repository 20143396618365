.filtersContainer {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.filterItemContainer {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
}
